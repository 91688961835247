import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AppBaseService } from "../../services/http.service";
import { Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { authProperties } from "../../authentication/authentication-properties";
import { EnvoirnmentService } from "./../../services/envoirnment.service";
import localForage from "localforage";
import { UserLoginModel, UserUnitList } from "./signin.model";
import { Subscription, ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ConfirmDynamicDialogComponent } from "./../../core/confirm-dynamic-dialog/confirm-dynamic-dialog.component";
import { MultiLanguageService } from "../../core/multi-language/multi-language.service";
import { MICROSERVICES } from "projects/medcare-core-ui/src/app/constants/web-services";
import { CookieService } from "ngx-cookie-service";
import { PushNotificationComponent } from "../../modules/push-notification/controllers/push-notification/push-notification.component";
import { ResetPasswordComponent } from "../reset-password/reset-password.component";
import { HttpClient } from "@angular/common/http";
import { APPLICATIONSERVICE } from "../../constants/application-service";


@Component({
  selector: "app-signin",
  templateUrl: "./signin.component.html",
  styleUrls: ["./signin.component.scss"],
  providers: [ PushNotificationComponent ]
})
export class SigninComponent implements OnInit, OnDestroy {
  private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);
  backgroundImage: string;
  public form: FormGroup;
  public password: string;
  public createOrforget: boolean;
  public sentornotfound: boolean;
  create = "C";
  forget = "F";
  sent = "S";
  notfound = "N";
  orgId: string = "";
  passcode: string;
  username: string = "";
  enterusername: string = "";
  firsttimepasscode: string = "";
  enterpassword: string = "";
  enterpasscode: string = "";
  emailid: string = "";
  enteremailID: string = "";
  emailnotfound: string = "";
  verificationemail: string = "";
  process: boolean = false;
  LoginBackgroundImage: string;
  hideRequiredControl = new FormControl(false);
  usernameToken: string = "";
  passwordToken: string = "";
  inputArray: number[] = [1, 2, 3, 4, 5, 6, 7, 8];
  isPasscode: boolean = true;
  isActiveDirectory: boolean = true;
  isQuickLogin: boolean = true;
  active: boolean = true;

  usernameValue: string = "";
  newpasswordValue: string = "";
  version :string="";

  showPasswordButton: boolean = true;
  showPassCodeButton: boolean = true;
  showActiveDirectoryButton: boolean = true;

  hasLoginPassCode: boolean = false;
  hasLoginPassword: boolean = false;
  hasLoginActiveDirectory: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private baseservice: AppBaseService,
    private langService: MultiLanguageService,
    private env: EnvoirnmentService, //@Inject("env") private env
    private cookieService: CookieService,
    private pushNotification: PushNotificationComponent,
    private http: HttpClient
  ) {}

  ngOnInit() {
    // mapping place holder to ts
    this.username = authProperties.username;
    this.passcode = authProperties.passcode;
    this.enterusername = authProperties.enterusername;
    this.firsttimepasscode = authProperties.firsttimepasscode;
    this.enterpassword = authProperties.enterpassword;
    this.enterpasscode = authProperties.enterpasscode;
    this.emailid = authProperties.emailid;
    this.enteremailID = authProperties.enteremailID;
    this.emailnotfound = authProperties.emailnotfound;
    this.verificationemail = authProperties.verificationemail;
    this.backgroundImage = authProperties.backgroundImage;
    this.LoginBackgroundImage = this.env.loginImage;
    this.version = this.env.medcareVerison;

    this.setLoginConfiguration();

    this.process = false;
    let isLoggedin = localStorage.getItem("isLoggedin");
    if (isLoggedin != null && isLoggedin == "true") {
      localStorage.removeItem("passcode");
      this.router.navigate(["Dashboard/dash"]);
    } else {
      localForage.config({
        driver: localForage.INDEXEDDB, // Force WebSQL; same as using setDriver()
        name: "localforage",
        version: 1.0,
        size: 9999999999, // Size of database, in bytes. WebSQL-only for now.
        storeName: "keyvaluepairs", // Should be alphanumeric, with underscores.
        description: "Medcare WebSQL Database",
      });
      localForage.clear();
      this.form = this.fb.group({
        uname: [null, Validators.compose([Validators.required])],
        password: [null, Validators.compose([Validators.required])],
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  /**
   *
   * @param id
   * @param nextInputIndex
   */
  onInputEntry(id, nextInputIndex) {
    let nexInput = +nextInputIndex + 1;
    let newID = id + nexInput;
    let textbox = document.getElementById(newID);
    if (textbox) textbox.focus();
  }

  /**
   *
   *
   * @memberof SigninComponent
   */
  setLoginConfiguration() {
    this.hasLoginPassCode = this.env.loginPassCode;
    this.hasLoginPassword = this.env.loginPassword
    this.hasLoginActiveDirectory = this.env.loginActiveDirectory;

    let loginTypesCount: number = 0;
    if(this.hasLoginPassCode) loginTypesCount++;
    if(this.hasLoginPassword) loginTypesCount++;
    if(this.hasLoginActiveDirectory) loginTypesCount++;

    if(loginTypesCount <= 1) {
      this.showPassCodeButton = false;
      this.showPasswordButton = false;
      this.showActiveDirectoryButton = false;
    }

    if(this.hasLoginPassCode) {
      this.isPasscode = true;
      this.isActiveDirectory = false;
      this.isQuickLogin = false;
    } else if(this.hasLoginPassword) {
      this.isPasscode = false;
      this.isActiveDirectory = false;
      this.isQuickLogin = true;
    } else if(this.hasLoginActiveDirectory) {
      this.isPasscode = false;
      this.isActiveDirectory = true;
      this.isQuickLogin = false;
    }
  }

  /**
   *
   *
   * @memberof SigninComponent
   */
  toggleActiveDirectoryLogin() {
    this.isPasscode = false;
    this.isQuickLogin = false;
    this.isActiveDirectory = true;

    this.showPasswordButton = true;
    this.showPassCodeButton = true;
    this.showActiveDirectoryButton = false;
  }
  
  /**
   *
   *
   * @memberof SigninComponent
   */
  togglePassCodeLogin() {
    this.isQuickLogin = false;
    this.isActiveDirectory = false
    this.isPasscode = true;
    
    this.showPasswordButton = true;
    this.showPassCodeButton = false;
    this.showActiveDirectoryButton = true;
  }

  /**
   *
   *
   * @memberof SigninComponent
   */
  toggleQuickLogin() {
    this.isActiveDirectory = false;
    this.isPasscode = false;
    this.isQuickLogin = true;
    
    this.showPasswordButton = false;
    this.showPassCodeButton = true;
    this.showActiveDirectoryButton = true;
  }

  /**
   *
   * @param id
   * @param nextInputIndex
   */
  onBackspace(id, nextInputIndex) {
    let nexInput = nextInputIndex - 1;
    let newID = id + nexInput;
    let textbox: any = document.getElementById(newID);
    if (textbox) {
      textbox.value = "";
      textbox.focus();
    }
  }

  /**
   * login API which gets triggered after entering 8 key passcode
   */
  loginMethod() {
    let pin: string = "";
    for (let i = 1; i <= 8; i++) {
      let textbox: any = document.getElementById("tab" + i);
      if (textbox) pin = pin + textbox.value;
    }
    try {
      if (pin && pin.length == 8) {
        this.process = true;
        this.baseservice.setResourceURL(MICROSERVICES.IDENTITY_SERVICE);
        this.baseservice
          .loginResource("user/login", {
            passCode: btoa(pin)
          })
          .pipe(takeUntil(this.unsubscribe))
          .subscribe({
            next: (res: any) => {
              let data: UserLoginModel = res.body;
              if (data) {
               this.getMachineIP();
                this.cookieService.set("isClinical", "true", 1, "/");

                localStorage.setItem("userId", btoa(data.userId));
                localStorage.setItem("employeeId",data.employeeDetails?data.employeeDetails.employeeId:"")
                localStorage.setItem("userName", btoa(data.userName));
                localStorage.setItem("userEmail", btoa(data.userEmailId));
                localStorage.setItem(
                  "authorization",
                  res.headers.get("Authorization")
                );
                localStorage.setItem("refreshtoken",res.headers.get("refresh-token"));
                let tokenValue = res.headers.get("Authorization");
                let refershValue=res.headers.get("refresh-token");
                let unit: UserUnitList = data.userUnitList.find(
                  (i) => i.isDefault == true
                );

                if (unit) {
                  localStorage.setItem("unitId", btoa(unit.unitId));
                  localStorage.setItem("unitCode", btoa(unit.unitCode));
                  localStorage.setItem("orgCode", btoa(unit.orgCode));
                  localStorage.setItem("orgId", btoa(unit.orgId));
                  localStorage.setItem("userRole", JSON.stringify(unit.userRole));
                  this.getRoleInfo(data, unit, tokenValue);

                  this.process = false;
                  const roleId = unit.userRole.roleId;
                  this.pushNotification.connect(roleId);
                } else {
                  this.snackBar.open(
                    "Validation",
                    "User does not have any default Unit",
                    {
                      duration: 3000,
                    }
                  );
                }
              }
            },
            error: (err) => {
              this.process = false;
              this.invalidLogin();
            },
          });
      }
    } catch (ex) {
      this.process = false;
      this.invalidLogin();
    }
  }

  /**
   * To fetch role information
   * @param data
   * @param unit
   */
  getRoleInfo(data, unit, tokenData) {
    data["userUnitList"] = data.userUnitList;
    data["roleNotificationMappers"] = null;
    data["userUnitRole"] = null;
    data["userUnit"] = null;
    data["authorization"] = tokenData ? tokenData : "";
    data["orgCode"] = unit.orgCode;
    localForage.setItem("unit", unit);
    localForage.setItem("role", {
      id:  unit.userRole?unit.userRole.roleId:"",
      roleName: unit.userRole?unit.userRole.roleDescription:"",
      roleCode: unit.userRole? unit.userRole.roleCode:"",
    });
    if(unit.userRole)
    localStorage.setItem("roleName", btoa(unit.userRole.roleDescription));

    // this.langService.getLanguageList();
    localForage.setItem("auth-data", data, (err) => {
      localStorage.setItem("lan", "en");
      localStorage.setItem("passcode", "true");
      localStorage.setItem("isLoggedin", "true");
      this.router.navigate(["Dashboard/dash"]);
    });
  }

  /**
   * To reset pin number
   */
  resetPin() {
    for (let i = 1; i <= 8; i++) {
      let textbox: any = document.getElementById("tab" + i);
      if (textbox) textbox.value = "";
    }
    document.getElementById("tab1").focus();
  }

  invalidLogin() {
    this.resetPin();
  }

  resetPinMethod() {
    this.usernameToken = "";
    this.passwordToken = "";
  }

  /**
   * Forgot passcode snackbar
   */
  forgotPasscode() {
    this.snackBar.open("Message", "Please Contact Admin to Reset Passcode", {
      duration: 10000,
    });
  }

  /**
   *
   *
   * @memberof SigninComponent
   */
  openForgotPasswordDialog() {
    let loginType: string = authProperties.loginTypePasscode;
    if(this.isPasscode) loginType = authProperties.loginTypePasscode;
    else if(this.isQuickLogin) loginType = authProperties.loginTypePassword;
    else if(this.isActiveDirectory) loginType = authProperties.loginTypeActiveDirectory;

    this.createOrforget = false;
    this.dialog.open(ResetPasswordComponent, {
      width: "450px",
      height: "auto",
      data: { loginType: loginType }
    });
  }

  /**
   * Virtual keyboard numbers
   * @param number
   */
  numberClick(number) {
    let valueSet = false;
    for (let i = 1; i <= 8; i++) {
      let textbox: any = document.getElementById("tab" + i);
      if (textbox && textbox.value == "" && !valueSet) {
        textbox.value = number;
        valueSet = true;
        if (i == 8) {
          this.loginMethod();
        }
      }
    }
  }

  clearNumber() {
    this.resetPin();
  }

  /**
   * Passcode create dialog
   */
  dilogCreatePasscode() {
    this.createOrforget = true;
    this.dialog.open(DialogOverviewExampleDialog, {
      width: "400px",
      height: "auto",
      data: {
        create: this.create,
        forget: this.forget,
        createOrforget: this.createOrforget,
      },
    });
  }

  /**
   * Forget passcode dialog
   */
  dilogForgetPasscode() {
    this.createOrforget = false;

    this.dialog.open(DialogOverviewExampleDialog, {
      width: "400px",
      height: "auto",
      data: {
        create: this.create,
        forget: this.forget,
        createOrforget: this.createOrforget,
      },
    });
  }

  /////Active Directory Code////
  loginMethodUserName() {
    this.createOrforget = true;
    this.dialog.open(CreatePassword, {
      width: "400px",
      height: "auto",
      data: {
        create: this.create,
        forget: this.forget,
        createOrforget: this.createOrforget,
      },
    });
  }

  loginMethodSubmit(directoryName) {
    try {
      if (this.usernameToken && this.passwordToken) {
        this.process = true;
        let loginData = {
          userName: this.usernameToken ? this.usernameToken : "",
          password: this.passwordToken ? this.passwordToken : "",
          login:directoryName?directoryName:null
        };

        this.baseservice.setResourceURL(MICROSERVICES.IDENTITY_SERVICE);
        this.baseservice
          .loginResource("user/login", loginData)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe({
            next: (res: any) => {
              let data: UserLoginModel = res.body;
              if (data && !data.isResetRequired) {
                this.getMachineIP();
                this.cookieService.set("isClinical", "true", 1, "/");

                localStorage.setItem("userId", btoa(data.userId));
                localStorage.setItem("userName", btoa(data.userName));
                localStorage.setItem("userEmail", btoa(data.userEmailId));
                localStorage.setItem("employeeId",data.employeeDetails?data.employeeDetails.employeeId:"");
                localStorage.setItem(
                  "authorization",
                  res.headers.get("Authorization")
                );
                let tokenValue = res.headers.get("Authorization");
                let unit: UserUnitList = data.userUnitList.find(
                  (i) => i.isDefault == true
                );

                if (unit) {
                  localStorage.setItem("unitId", btoa(unit.unitId));
                  localStorage.setItem("unitCode", btoa(unit.unitCode));
                  localStorage.setItem("orgCode", btoa(unit.orgCode));
                  localStorage.setItem("orgId", btoa(unit.orgId));
                  localStorage.setItem("userRole", JSON.stringify(unit.userRole));
                  this.getRoleInfo(data, unit, tokenValue);

                  this.process = false;
                } else {
                  this.snackBar.open(
                    "Validation",
                    "User does not have any default Unit",
                    {
                      duration: 3000,
                    }
                  );
                }
              } else if(data && data.isResetRequired) {
                this.openPasswordResetDialog();
              }
            },
            error: (err) => {
              this.process = false;
              this.invalidLogin();
            },
          });
      }
    } catch (ex) {
      this.process = false;
      this.invalidLogin();
    }
  }

  /**
   *
   *
   * @memberof SigninComponent
   */
  openPasswordResetDialog() {
    let loginType: string = authProperties.loginTypePasscode;
    if(this.isPasscode) loginType = authProperties.loginTypePasscode;
    else if(this.isQuickLogin) loginType = authProperties.loginTypePassword;
    else if(this.isActiveDirectory) loginType = authProperties.loginTypeActiveDirectory;
    
    this.createOrforget = false;
    this.dialog.open(ResetPasswordComponent, {
      width: "400px",
      height: "auto",
      data: { firstTimeLogin: true, loginType: loginType, email: this.usernameToken, password: this.passwordToken }
    });
  }

  /**
   * To retrieve login information
   */
  getLoginData() {
    this.baseservice.setResourceURL(MICROSERVICES.ID_SERVICE);
    this.baseservice
      .postResource("users/adUser", { username: this.usernameToken })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (data: UserLoginModel) => {
          if (data) {
            localStorage.setItem("userId", btoa(data.userId));
            localStorage.setItem("userName", btoa(data.userName));
            localStorage.setItem("orgId", btoa(data.orgId));

            let unit: UserUnitList = data.userUnitList.find(
              (i) => i.isDefault == true
            );
            if (unit) {
              localStorage.setItem("unitId", btoa(unit.unitId));
              localStorage.setItem("unitCode", btoa(unit.unitCode));
              localStorage.setItem("unitPrefix", btoa(unit.unitPrefix));
              localStorage.setItem("orgCode", btoa(unit.orgCode));

              this.getRoleInfo(data, unit, "");
              this.process = false;
            } else {
              this.snackBar.open(
                "Validation",
                "User does not have any default Unit",
                {
                  duration: 3000,
                }
              );
            }
          }
        },
        error: (err) => {
          this.process = false;
          this.invalidLogin();
        },
      });
  }

  getMachineIP() {
    // this.http.get<{ip:string}>(APPLICATIONSERVICE.GET_MACHINE_IP)
    // .subscribe( data => {
    //   localStorage.setItem("machineIP", btoa(data.ip));
    // });
  }
}

@Component({
  selector: "dialog-overview-example-dialog",
  templateUrl: "create-passcode.html",
  styleUrls: ["./signin.component.scss"],
})
export class DialogOverviewExampleDialog implements OnDestroy {
  private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);
  private subscriptionList = new Subscription();

  createOrforget: string;
  username: string;
  passcode: string;
  enterusername: string = "";
  firsttimepasscode: string = "";
  enterpassword: string = "";
  enterpasscode: string = "";
  confirmpasscode: string = "";
  emailid: string = "";
  enteremailID: string = "";
  emailnotfound: string = "";
  verificationemail: string = "";
  usernameValue: string = "";
  passwordValue: string = "";
  passcodeValue: string = "";
  passcodeConfirmValue: string = "";
  useremailValue: string = "";
  pinValue: string = "";

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private baseservice: AppBaseService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {
    this.username = authProperties.username;
    this.passcode = authProperties.passcode;
    this.enterusername = authProperties.enterusername;
    this.firsttimepasscode = authProperties.firsttimepasscode;
    this.enterpassword = authProperties.enterpassword;
    this.enterpasscode = authProperties.enterpasscode;
    this.confirmpasscode = authProperties.confirmpasscode;
    this.emailid = authProperties.emailid;
    this.enteremailID = authProperties.enteremailID;
    this.emailnotfound = authProperties.emailnotfound;
    this.verificationemail = authProperties.verificationemail;
  }

  ngOnDestroy() {
    this.subscriptionList.unsubscribe();
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  resetData() {
    this.usernameValue = "";
    this.passwordValue = "";
    this.pinValue = "";
  }

  /**
   * To call forget passcode API
   */
  forgetpasscode() {
    let mailid = this.useremailValue;
    this.baseservice.setResourceURL(MICROSERVICES.IDENTITY_SERVICE);
    this.baseservice
      .getResource("user/byEmail/" + mailid)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.snackBar.open("success", "mail sent successfully", {
          duration: 1000,
        });
        this.useremailValue = "";
        this.dialogRef.close();
      });
  }

  /**
   * Focus out
   */
  focusOut() {
    let obj = {
      username: this.usernameValue,
      password: this.passwordValue,
      passCode: null,
      userAutoSeqNo: null,
      firstPassCode: null,
      confirmPassCode: null,
    };
    this.baseservice.setResourceURL(MICROSERVICES.IDENTITY_SERVICE);
    this.baseservice
      .postResource("user/getUserPin", obj)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.pinValue = data.userPin;
      });
  }

  /**
   * Confirmation popup
   */
  confirmSave() {
    if (this.usernameValue) {
      if (this.passcodeConfirmValue) {
    const dialogRef = this.dialog.open(ConfirmDynamicDialogComponent, {
      width: "30%",
      data: { displayText: "saveConfirm" },
    });
    this.subscriptionList.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result != undefined && result.confirm && result.confirm == "yes") {
          this.saveData();
        }
      })
    );
      }
    else {
      this.snackBar.open("Warning", "Please enter Confirm Password" ,{
        duration: 3000,
      });
    }
  } else {
    this.snackBar.open("Warning", "Please enter User Name and Password", {
      duration: 3000,
    });
  }
  }

  /**
   * To save passcode
   */
  saveData() {
    if (this.passcodeValue == this.passcodeConfirmValue) {
      let obj = {
        password: this.passwordValue,
        passCode: this.passcodeValue,
        username: this.usernameValue,
        userAutoSeqNo: null,
        firstPassCode: null,
        confirmPassCode: null,
      };
      this.baseservice.setResourceURL(MICROSERVICES.IDENTITY_SERVICE);
      this.baseservice
        .putResource("user/generatePasscode", obj)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((data) => {
          this.snackBar.open("Success", "Passcode successfully generated", {
            duration: 1000,
          });
          this.onNoClick();
        });
    }
  }
}

@Component({
  selector: "create-password",
  templateUrl: "create-password.html",
  styleUrls: ["./signin.component.scss"],
})
export class CreatePassword implements OnDestroy {
  private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);
  private subscriptionList = new Subscription();
  createOrforget: string;
  username: string;
  passcode: string;
  enterusername: string = "";
  firsttimepasscode: string = "";
  enterpassword: string = "";
  enterpasscode: string = "";
  usernameValue: string = "";
  passwordValue: string = "";
  newpasswordValue: string = "";
  newpassword: string = "";

  constructor(
    public dialogRef: MatDialogRef<CreatePassword>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private baseservice: AppBaseService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {
    this.username = authProperties.username;
    this.passcode = authProperties.passcode;
    this.enterusername = authProperties.enterusername;
    this.firsttimepasscode = authProperties.firsttimepasscode;
    this.enterpassword = authProperties.enterpassword;
    this.enterpasscode = authProperties.enterpasscode;
  }

  ngOnDestroy() {
    this.subscriptionList.unsubscribe();
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  /**
   * Focus out
   */
  focusOut() {
    let obj = {
      username: this.usernameValue,
      password: this.passwordValue,
    };
    this.baseservice.setResourceURL(MICROSERVICES.IDENTITY_SERVICE);
    this.baseservice
      .postResource("user/validateKeycloak", obj)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.newpassword = data.newpasswordValue;
      },
      (error) => {
        if (error.status === 409) {
          this.usernameValue = '';
          this.passwordValue = '';
        }
      }
      );
  }

  /**
   * Confirmation popup
   */
  confirmSave() {
    const dialogRef = this.dialog.open(ConfirmDynamicDialogComponent, {
      width: "30%",
      data: { displayText: "saveConfirm" },
    });
    this.subscriptionList.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result != undefined && result.confirm && result.confirm == "yes") {
          this.saveData();
        }
      })
    );
  }

  /**
   * To save passcode
   */
  saveData() {
    if (this.passwordValue != this.newpasswordValue) {
      let obj = {
        password: this.passwordValue,
        newpassword: this.newpasswordValue,
        username: this.usernameValue,
      };
      this.baseservice.setResourceURL(MICROSERVICES.IDENTITY_SERVICE);
      this.baseservice
        .putResource("user/generateKeycloakPassword", obj)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((data) => {
          this.snackBar.open("success", "Keycloak successfully generated", {
            duration: 1000,
          });
          this.onNoClick();
        });
    }
  }
}
